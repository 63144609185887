import axios from "axios";
import get from "lodash/get";

const BACKENDBASE = "https://syllables.stagingsite.app/";

export async function searchWord(word = "") {
  word = encodeURI(word);
  if (!word || word == "") {
    return;
  }
  const definitionPromise = axios.get(
    `${BACKENDBASE}/definition.php?word=${word}`
  );
  const syllablesPromise = axios.get(
    `${BACKENDBASE}/syllables.php?word=${word}&output=json`
  );
  const [definition, syllables] = await Promise.all([
    definitionPromise,
    syllablesPromise
  ]);
  const definitions = [];
  if (definition.data.results) {
    definition.data.results.forEach(result => {
      if (result.lexicalEntries) {
        result.lexicalEntries.forEach(le => {
          if (le.entries) {
            le.entries.forEach(entry => {
              if (entry.senses) {
                entry.senses.forEach(sense => {
                  let wordDefinition = {
                    full: "",
                    short: "",
                    examples: [],
                    partOfSpeech: get(le, "lexicalCategory", "").toLowerCase()
                  };
                  if (sense.definitions) {
                    wordDefinition.full = sense.definitions.join("; ");
                    wordDefinition.short = get(
                      sense,
                      "short_definitions",
                      []
                    ).join("; ");
                    wordDefinition.examples = get(sense, "examples", []);
                    definitions.push(wordDefinition);
                  }
                  if (sense.subsenses) {
                    sense.subsenses.forEach(subsense => {
                      let wordDefinition = {
                        full: "",
                        short: "",
                        examples: [],
                        partOfSpeech: get(
                          le,
                          "lexicalCategory",
                          ""
                        ).toLowerCase()
                      };
                      if (subsense.definitions) {
                        wordDefinition.full = subsense.definitions.join("; ");
                        wordDefinition.short = get(
                          subsense,
                          "short_definitions",
                          []
                        ).join("; ");
                        wordDefinition.examples = get(subsense, "examples", []);
                        definitions.push(wordDefinition);
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
  }
  const result = {
    word,
    syllables: get(syllables, "data.syllables", "").split("-"),
    stresses: get(syllables, "data.stresses[0]", "").split(""),
    definitions
  };
  return result;
}
