import React, { Component } from "react";
import get from "lodash/get";
import { searchWord } from "../../utilities/api";
import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { words: [], searchText: "" };
  }

  updateSearchText = e => {
    this.setState({ ...this.state, searchText: get(e, "target.value", "") });
  };

  handleSearch = e => {
    e.preventDefault();
    const query = get(this.state, "searchText", "");
    if (!query) {
      return;
    }
    //clear form
    this.setState({ ...this.state, searchText: "" }, async () => {
      const queryResult = await searchWord(query);
      console.log(queryResult);
      this.setState({
        ...this.state,
        words: [...this.state.words, queryResult]
      });
    });
  };

  render() {
    return (
      <div className="App">
        <div>
          <form onSubmit={this.handleSearch}>
            <input
              name="searchText"
              type="text"
              value={this.state.searchText}
              onChange={e => {
                this.updateSearchText(e);
              }}
            />
            <input type="submit" value="Search" onClick={this.handleSearch} />
          </form>
        </div>
        <div>
          {this.state.words.map((w, i) => {
            //  word,
            //  syllables: get(syllables, "data.syllables"),
            //  stresses: get(syllables, "data.stresses"),
            //  definitions
            const { word, syllables, stresses, definitions } = w;
            let emphasized = "";
            let emphasizedError = false;
            if (syllables.length === stresses.length) {
              emphasized = syllables.map((syllable, j) => {
                if (syllable === "" || syllable.length === 0) {
                  emphasizedError = true;
                }
                if (parseInt(stresses[j]) === 1) {
                  return (
                    <span className="syllable syllable--stressed">
                      <strong>{syllable}</strong>
                    </span>
                  );
                } else {
                  return <span className="syllable">{syllable}</span>;
                }
              });
            }
            let definition = "";
            const firstDefinition = get(definitions, "[0]", {});

            if (firstDefinition.full) {
              definition = (
                <>
                  {firstDefinition.partOfSpeech
                    ? `[${firstDefinition.partOfSpeech}] `
                    : ""}
                  {firstDefinition.full}
                </>
              );
              // full: "",
              // short: "",
              // examples: [],
              // partOfSpeech:
            }

            const example = get(firstDefinition, "examples[0].text", "");
            let wordPrefix =
              get(firstDefinition, "partOfSpeech", "") === "verb" ? "to " : "";
            const headword = emphasizedError
              ? word
              : emphasized
              ? emphasized
              : word;
            return (
              <p key={i}>
                {wordPrefix}
                {headword}: {definition}
                <br />
                {example ? `Ex: ${example}` : ""}
                <br />
                &nbsp;
                <br />
              </p>
            );
          })}
        </div>
      </div>
    );
  }
}

export default App;
